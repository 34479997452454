import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import Amplify, { API, Auth, graphqlOperation } from 'aws-amplify';
import {  Loader } from '@aws-amplify/ui-react';
import { listApplications, getOrganization, userByUserName } from '../../graphql/queries';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import logger from '../../Logger';

// components
import Header from '../../components/header/Header';
import Footer from '../../components/Footer';

// Style
import './x5client.scss';


Amplify.configure({
    API: {
      endpoints: [
        {
          name: "appstreamsessionApi",
          custom_header: async () => { 
            return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          }
        }
      ]
    }
  });

const X5client = (props) => {
    const [userInfo, setUserInfo] = useState();
    const [appsList, setAppsList] = useState([]);
    const [selectedApp, setSelectedApp] = useState({});
    const [apiError, setApiError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLaunching, setIsLaunching] = useState(false);
    const [isAppselected, setIsAppselected] = useState(false);
  
    const hasStackAssigned = appsList.length > 0;
  
    useEffect(() => {
      fetchAppstreamAssignments();
      return () => {
        closeApp();
        logger.info('Closed App');
      };
    }, []);
  
    const fetchAppstreamAssignments = async()=> {
      setIsLoading(true);
      setIsLaunching(false);
      try {
        const user = await fetchUserInformation();
        await fetchAppstreamAssignmentsForOrg(user);
        setApiError(null);
      } catch (err) { 
        logger.error('Failed Fetching User Application Assignments:', err);
        setApiError(err);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchUserInformation = async () => {
      try {
        const user = await Auth.currentUserInfo();
        const username = user.username;
        const asUserListData = await API.graphql(graphqlOperation(userByUserName, {username: username}));
        if (asUserListData.data.userByUserName.items && asUserListData.data.userByUserName.items.length > 0) {
          const user = asUserListData.data.userByUserName.items[0];
          logger.info(user);
          setUserInfo(user);
          return user;
        } else {
          throw 'Not assigned to an Organization';
        }
      } catch (err) { 
        logger.error('Failed Fetching User Info:', err);
        setApiError(err);
      } finally { }
    };
  
    const fetchAppstreamAssignmentsForOrg = async (user) => {
      try {
        const orgId = user.organizationId;
        const org = await API.graphql(graphqlOperation(getOrganization, {id: orgId} ));
        const apps = org.data.getOrganization.applications.items;
        apps.sort((a, b) => {
          var appDNA = a.displayName.toUpperCase(); // ignore upper and lowercase
          var appDNB = b.displayName.toUpperCase(); // ignore upper and lowercase
          if (appDNA < appDNB) {
            return -1;
          }
          if (appDNA > appDNB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
        logger.info('Apps', apps);
        setAppsList(apps);
      } catch (err) { 
        logger.error('Failed Fetching User Stack Assignments:', err);
        setApiError(err);
      } finally {  }
    };
  
    const launchApp = async (asstack, asfleet, asapp, asip) => {
        setIsAppselected(true);
        setIsLaunching(true);
        setSelectedApp({
            stack: asstack,
            fleet: asfleet,
            app: asapp,
            x5serverIP: asip
        });
        const url = await createStreamingUrl(asstack, asfleet, asapp, asip);
        logger.info('Url created.', url);
        if (url) {
            setIsLaunching(false);
            launchAppStreamSession(url);
            logger.info('app launched');
        }
    }

    const createStreamingUrl = async(stack, fleet, app, ip) => {
      const user = await Auth.currentUserInfo();
      logger.info('hi');

      const username = user.username;
      const apiName = 'appstreamSessionApi';
      const path = '/appstream';
      
      const params = {
        body: {
          user: username,
          fleet: fleet,
          stack: stack,
          application: app,
          x5serverIP: ip
        }
      };
      logger.info(params);
      try {
        const response = await API.post(apiName, path, params);
        logger.info(response);
        return response.AppstreamSessionCustomUrl;
      } catch (err) {
        logger.error('Failed generating Appstream URL (x5client):', err);
        setApiError(err);
        isAppselected(false);
        setIsLaunching(false);
        return null;
      } finally {
        
      }
    }

    const closeApp = async() => {
        logger.info('Closing App...');
        setIsAppselected(false);
        setIsLaunching(false);
        setIsLoading(false);
        destroyAppStreamFrame();
    }
  
    let appstreamEmbed;
    const AppStream = window.AppStream;
  
    const destroyAppStreamFrame = () => {
      if (appstreamEmbed) {
        appstreamEmbed.endSession();
          appstreamEmbed.destroy();
          appstreamEmbed = null;
          //delete appstreamEmbed;
      }
    }
  
    const launchAppStreamSession = async (asStreamingUrl) => {          
        destroyAppStreamFrame();
        var userInterfaceConfig = {};
        //hide entire toolbar
        //userInterfaceConfig[AppStream.Embed.Options.HIDDEN_ELEMENTS] = [AppStream.Embed.Elements.TOOLBAR];
        //hide elemetns on toolbar
        userInterfaceConfig[AppStream.Embed.Options.HIDDEN_ELEMENTS] = [AppStream.Embed.Elements.CATALOG_BUTTON,AppStream.Embed.Elements.WINDOW_SWITCHER_BUTTON,AppStream.Embed.Elements.SETTINGS_BUTTON];

        if (asStreamingUrl && asStreamingUrl.length > 0) {
            var appstreamOptions = {
                sessionURL: asStreamingUrl,
                userInterfaceConfig: userInterfaceConfig
            };
            appstreamEmbed = new AppStream.Embed("x5as2-container", appstreamOptions);
            appstreamEmbed.addEventListener(AppStream.Embed.Events.SESSION_STATE_CHANGE, updateSessionStateCallback);
            appstreamEmbed.addEventListener(AppStream.Embed.Events.SESSION_INTERFACE_STATE_CHANGE, updateUserInterfaceStateCallback);
            appstreamEmbed.addEventListener(AppStream.Embed.Events.SESSION_ERROR, errorCallback);
            
        } else {
          logger.error('Invalid AS2 URL');
        }
    }
  
    const updateSessionStateCallback = (event) => {
        let status = event[AppStream.Embed.EventParams.STATUS];
        switch(AppStream.Embed.SessionStatus[status]) {
            case AppStream.Embed.SessionStatus.Unknown:
                logger.info('Embed AS2 Status Unkown');
                break;
            case AppStream.Embed.SessionStatus.Started:
                  logger.info('Embed AS2 Status Started');
                break;
            case AppStream.Embed.SessionStatus.Disconnected:
              logger.info('Embed AS2 Status Dsconnected');
              closeApp();
              break;
            case AppStream.Embed.SessionStatus.Ended:
              logger.info('Embed AS2 Status Ended');
              closeApp();
              break;
            default:
                break;
        }
        logger.info('embed as2 updated sessionstate event', event);
    }
  
    const updateUserInterfaceStateCallback = (event) => {
      logger.info('embed as2 interface change', event);
    }

    const errorCallback = (event) => {
      setApiError('Error with Embed AS2');
      logger.error('Error with Embed AS2', event);
      closeApp();
    }
  
  return (
    <>
    <Helmet>
      <meta http-equiv="Access-Control-Allow-Origin" content="null" />
    </Helmet>
      <Header />
      {isAppselected ?
      <>
        {isLaunching ?
            <>
            <Container fluid="md mt-4">
                <Row md="auto" className="justify-content-md-center">
                    <Col md="auto">
                        Launching X5....
                        <Loader variation="linear" />
                    </Col>
                </Row>
            </Container>
            </> :
            <>
                <div id="x5as2-container" className="x5as2-container">
                </div>
            </>
        }
      </>
      : 
      <>
      <Container fluid="md mt-4">
      <Row md="auto" className="justify-content-md-center">
          <Col md="auto">
            {isLoading ?
            <>
              <Loader variation="linear" />
            </>
            :
            <>
            {hasStackAssigned ?
                <ul className="asStreamList">
                {
                appsList.map((app, index) => (
                    <li key={app.id ? app.id : index} onClick={() => launchApp(app.stack, app.fleet, app.application, app.x5serverIP)} >
                    <div className='asStreamButton'>{app.displayName}</div>
                    </li>
                ))
                }
                </ul>
                : <div>
                    <Alert variant="light">
                        <Alert.Heading>Account Setup</Alert.Heading>
                        <p>
                            Please reach out to the <Alert.Link href="https://safetynet.xiltrixusa.com/hc/en-us/requests/new">SafetyNet</Alert.Link> Team to finish provisioning your account.
                        </p>
                        <p>
                            Send an email to <Alert.Link href="mailto:safetynet@xiltrixusa.com/">SafetyNet@xiltrixusa.com</Alert.Link> or submit a ticket 
                            on our <Alert.Link href="https://safetynet.xiltrixusa.com/hc/en-us/requests/new">online portal</Alert.Link> and request access to your X5 Server.
                        </p>
                    </Alert>   
                </div>
                }
                    </>
            }
          </Col>
            </Row>
        </Container>
        <Footer />
        </>
      }

        
    </>
  )
}

export default X5client;